export const geschlechtObj = [
  { label: 'Männlich', id: 'Männlich' },
  { label: 'Weiblich', id: 'Weiblich' },
  { label: 'Divers', id: 'Divers' },
  { label: 'Keine Angabe', id: 'none' },
];

export const corpsFunction = [
  'Funktionsträger:in Ortsebene',
  'Funktionsträger:in Kreisebene',
  'Funktionsträger:in Bezirksebene',
  'Funktionsträger:in Landesebene',
];

export const corpsStatuses = [
  { label: 'AH', id: 'AH' },
  { label: 'AH-IdC', id: 'AH-IdC' },
  { label: 'B', id: 'B' },
  { label: 'CB', id: 'CB' },
  { label: 'CK', id: 'CK' },
  { label: 'EB', id: 'EB' },
  { label: 'EM', id: 'EM' },
  { label: 'F', id: 'F' },
  { label: 'HD', id: 'HD' },
  { label: 'iaB', id: 'iaB' },
  { label: 'iaCB', id: 'iaCB' },
  { label: 'iaM/iaD', id: 'iaM/iaD' },
  { label: 'IdC', id: 'IdC' },
  { label: 'M/D', id: 'M/D' },
  { label: 'ZM', id: 'ZM' },
];

const employmentType = [
  { label: 'Keine', id: 'Keine' },
  {
    label: 'Beamte/-r oder Angestellte/-r (Vollzeit)',
    id: 'Beamte/-r oder Angestellte/-r (Vollzeit)',
  },
  {
    label: 'Beamte/-r oder Angestellte/-r (Teilzeit)',
    id: 'Beamte/-r oder Angestellte/-r (Teilzeit)',
  },
  { label: 'LiV / Ref / LAA', id: 'LiV / Ref / LAA' },
  { label: 'Studierende/-r', id: 'Studierende/-r' },
  { label: 'Im Ruhestand', id: 'Im Ruhestand' },
  { label: 'Sonstige', id: 'Sonstige' },
];

export const activityType = [
  'Biologie ',
  'Chemie',
  'Darstellendes Spiel',
  'Deutsch',
  'Deutsch als Fremd-/Zweitsprache',
  'Englisch',
  'Ethik',
  'Evangelische Religion',
  'Französisch',
  'Geographie',
  'Geschichte',
  'Gesundheit und Soziales',
  'Griechisch',
  'Hauswirtschaft / Arbeitslehre',
  'Informatik',
  'Islamischer Religionsunterricht',
  'Italienisch',
  'Katholische Religion',
  'Kunst',
  'Latein',
  'Mathematik',
  'Musik',
  'Naturwissenschaften',
  'Ökologie',
  'Philosophie',
  'Physik',
  'Politik',
  'Recht',
  'Religionskunde',
  'Russisch',
  'Sozialkunde',
  'Spanisch',
  'Sport',
  'Technik',
  'Wirtschaft',
  'Keine',
  'Sonstige',
];

const seniorityType = [
  'In Ausbildung',
  'Lehrkraft',
  'StR / StR’n',
  'Oberstufenkoordination',
  'Fachbereichsleitung',
  'OStR / StR’n',
  'OStD / OStD’n',
  'Schulleitung',
  'Keine / Sonstige',
];

export const branchType = [
  { label: 'Gymnasium', id: 'Gymnasium' },
  { label: 'Berufsbildende Schule', id: 'Berufsbildende Schule' },
  { label: 'Gymnasiale Oberstufe', id: 'Gymnasiale Oberstufe' },
  { label: 'Realschule', id: 'Realschule' },
  { label: 'Hauptschule / Mittelschule', id: 'Hauptschule / Mittelschule' },
  { label: 'Sekundarschule', id: 'Sekundarschule' },
  { label: 'Oberschule', id: 'Oberschule' },
  { label: 'Gesamtschule', id: 'Gesamtschule' },
  { label: 'Gemeinschaftsschule', id: 'Gemeinschaftsschule' },
  { label: 'Sonstiges', id: 'Sonstiges' },
];

export const specialityType = {
  'Architektur und Bauwesen': [
    { label: 'Architektur' },
    { label: 'Baugewerbe' },
    { label: 'Bauingenieurwesen' },
    { label: 'Baustoffe' },
    { label: 'Garten- und Landschaftsbau' },
  ],
  'Automobil und Fahrzeugbau': [
    { label: 'Automobile und Zweiräder' },
    { label: 'Fahrzeugvermietung' },
    { label: 'Luft- und Raumfahrtzeugbau' },
    { label: 'Schienenfahrzeugbau' },
    { label: 'Schiffbau' },
    { label: 'Verkehrstechnik' },
  ],
  'Banken und Finanzdienstleistungen': [
    { label: 'Bankwesen' },
    { label: 'Finanzdienstleistungen' },
    { label: 'Investmentbanken' },
    { label: 'Risikokapital und Private Equity' },
  ],
  'Beratung und Consulting': [
    { label: 'Managementberatung' },
    { label: 'IT-Beratung' },
    { label: 'Ingenieurdienstleistungen' },
    { label: 'Sonstige Beratung' },
  ],
  'Energie, Wasser und Umwelt': [
    { label: 'Abfälle und Recycling' },
    { label: 'Energiewirtschaft' },
    { label: 'Erneuerbare Energien' },
    { label: 'Umweltschutz' },
    { label: 'Wasserversorgung und -entsorgung' },
  ],
  'Erziehung, Bildung und Wissenschaft': [
    { label: 'Coaching und Fortbildung' },
    { label: 'E-Learning' },
    { label: 'Fach- und Hochschulen' },
    { label: 'Forschung' },
    { label: 'Kinderbetreuung' },
    { label: 'Schulen und Kindergärten' },
  ],
  'Gesundheit und Soziales': [
    { label: 'Alternative Medizin' },
    { label: 'Arztpraxen' },
    { label: 'Krankenhäuser' },
    { label: 'Medizinische Dienste' },
    { label: 'Pflegeberufe' },
    { label: 'Pharmazie' },
    { label: 'Psychologie und Psychotherapie' },
    { label: 'Sozialwesen' },
    { label: 'Tiermedizin' },
  ],
  'Holz- und Forstwirtschaft': [{ label: 'Holzhandel' }, { label: 'Holzindustrie' }, { label: 'Holzhandwerk' }],
  Immobilien: [{ label: 'Facility Management' }, { label: 'Immobilienvermittlung' }, { label: 'Immobilienverwaltung' }],
  'Industrie und Maschinenbau': [
    { label: 'Bio- und Nanotechnologie' },
    { label: 'Chemie' },
    { label: 'Druck' },
    { label: 'Elektrotechnik' },
    { label: 'Halbleiter und elektronische Bauteile' },
    { label: 'Kunststoff und Gummiwaren' },
    { label: 'Maschinenbau und Betriebstechnik' },
    { label: 'Mess- und Regeltechnik' },
    { label: 'Metallindustrie und -verarbeitung' },
    { label: 'Mineralölverarbeitung' },
    { label: 'Optische und fotografische Geräte' },
    { label: 'Rüstung' },
    { label: 'Verbundwerkstoffe' },
  ],
  'Internet und Informationstechnologie': [
    { label: 'Computer-Hardware' },
    { label: 'Computernetzwerke' },
    { label: 'Computerspiele' },
    { label: 'IT-Dienstleister' },
    { label: 'IT-Sicherheit' },
    { label: 'Internet und Onlinemedien' },
    { label: 'Software' },
    { label: 'Unterhaltungselektronik' },
  ],
  'Konsumgüter und Handel': [
    { label: 'Einzelhandel' },
    { label: 'Getränke' },
    { label: 'Glas und Keramik' },
    { label: 'Großhandel' },
    { label: 'Import und Export' },
    { label: 'Kosmetik und Körperflege' },
    { label: 'Lebensmittel' },
    { label: 'Luxusgüter und Schmuck' },
    { label: 'Möbel und Holzwaren' },
    { label: 'Mode und Textilien' },
    { label: 'Papierwaren' },
    { label: 'Tabakwaren' },
  ],
  'Kunst, Kultur und Sport': [
    { label: 'Bibliotheken' },
    { label: 'Darstellende Kunst' },
    { label: 'Fotografie' },
    { label: 'Kunst und Kunsthandwerk' },
    { label: 'Museen und Kultureinrichtungen' },
    { label: 'Musik' },
    { label: 'Sportler, Veranstalter und Verbände' },
    { label: 'Sportvereine, Sportanlagen und Fitnesszentren' },
  ],
  'Marketing, PR und Design': [
    { label: 'Design und Grafik' },
    { label: 'Marketing und Werbung' },
    { label: 'Markt- und Meinungsforschung' },
    { label: 'Messe, Ausstellungen und Kongresse' },
    { label: 'Öffentlichkeitsarbeit und Kommunikation' },
  ],
  'Medien und Verlage': [
    { label: 'Film und Musik' },
    { label: 'Informationsdienste' },
    { label: 'Journalismus' },
    { label: 'Rundfunk und Fernsehen' },
    { label: 'Text und Lektorat' },
    { label: 'Übersetzen und Dolmetschen' },
    { label: 'Verlagswesen' },
  ],
  'Öffentlicher Dienst, Verbände und Einrichtungen': [
    { label: 'Gemeinnützige Einrichtungen und Vereine' },
    { label: 'Internationale Angelegenheiten' },
    { label: 'Öffentliche Verwaltung' },
    { label: 'Politik und Verbände' },
    { label: 'Religiöse Einrichtungen' },
    { label: 'Verteidigung, Justiz und Polizei' },
  ],
  Personaldienstleistungen: [
    { label: 'Outsourcing und Offshoring' },
    { label: 'Personaldienstleistungen und -beratung' },
  ],
  'Pharma- und Medizintechnik': [{ label: 'Medizintechnik' }, { label: 'Pharmazeutische Produkte und Arzneimittel' }],
  Telekommunikation: [],
  'Tourismus und Gastronomie': [
    { label: 'Gastronomie' },
    { label: 'Hotelgewerbe' },
    { label: 'Reisebüros und -veranstalter' },
    { label: 'Vergnügungsparks und Freizeiteinrichtungen' },
  ],
  'Transport, Verkehr und Logistik': [
    { label: 'Bahnverkehr' },
    { label: 'Lagerhaltung' },
    { label: 'Luftverkehr' },
    { label: 'Personenverkehr' },
    { label: 'Post und Spedition' },
    { label: 'Schifffahrt' },
  ],
  Versicherungen: [],
  'Wirtschaftsprüfung, Steuern und Recht': [
    { label: 'Notariat' },
    { label: 'Rechtsberatung mit Notariat' },
    { label: 'Rechtsberatung ohne Notariat' },
    { label: 'Steuerberatung' },
    { label: 'Wirtschaftsprüfung' },
  ],
  'Sonstige Branchen': [
    { label: 'Bergbau und Metalle' },
    { label: 'Fischerei' },
    { label: 'Jagd' },
    { label: 'Geologie' },
    { label: 'Landwirtschaft' },
    { label: 'Sicherheit und Ermittlungen' },
    { label: 'Sonstige Dienstleistungen' },
    { label: 'Spiel-, Wett- und Lotteriewesen' },
  ],
  'Metall & Elektronik': [
    'Elektrotechnik und Elektroindustrie',
    'Maschinen- und Anlagenbau',
    'Metallverarbeitung',
    'Feinmechanik, Optik, Uhren',
    'Ziehereien und Kaltwalzwerke',
    'Nichteisen-Metallerzeugung',
    'Luft- und Raumfahrtindustrie',
    'Gießereien',
    'Schienenfahrzeugbau',
    'Schiffbau und Meerestechnik',
  ],
};

export const educationLevel = [
  { label: 'Keine Angabe', id: 'Keine Angabe' },
  { label: 'Bachelor', id: 'Bachelor' },
  { label: 'Diplom', id: 'Diplom' },
  { label: 'Erstes Staatsexamen', id: 'Erstes Staatsexamen' },
  { label: 'Magister', id: 'Magister' },
  { label: 'Master', id: 'Master' },
  { label: 'Promotion', id: 'Promotion' },
  { label: 'Zweites Staatsexamen', id: 'Zweites Staatsexamen' },
];

export default {
  geschlechtObj,
  corpsFunction,
  corpsStatuses,
  employmentType,
  activityType,
  seniorityType,
  branchType,
  specialityType,
  educationLevel,
};
